.kyc-modal {
  .modal-content {
    background-color: #f8f8fb;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  &__content {
    padding: 2rem;
    text-align: center;
  }

  &__title {
    color: #495057;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__text {
    color: #74788d;
    font-size: 16px;
    margin-bottom: 1.5rem;
    line-height: 1.5;
    text-align: center;
  }

  &__button {
    min-width: 200px;
    margin: 0 auto;
    font-weight: 500;
    background-color: #13b9ae !important;
    border-color: #13b9ae !important;
    width: 100%;

    &:hover, &:focus, &:active {
      background-color: darken(#13b9ae, 5%) !important;
      border-color: darken(#13b9ae, 5%) !important;
    }
  }

  .text-primary {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .btn-close {
    padding: 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
    opacity: 0.5;
    transition: opacity 0.15s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  .alert-warning {
    display: flex;
    flex-direction: column;
    border: none;
  }

  .alert-success {
    display: flex;
    flex-direction: column;
    border: none;
  }
}

.user-id-block {
  display: flex;
  align-content: center !important;
  justify-content: center;
  gap: 6px;
  margin-top: 8px;

  p {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}
